import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

// Components
import Header from '../components/Header.jsx'
import Footer from '../components/Footer.jsx'
// images
import citybackground from '../images/hero-background.jpg';
import whereToUseInvenseBackground from '../images/where-to-use-invense-background.png';

// Images
import Product1 from '../images/products/product-1.png';
// import Product2 from '../images/products/product-2.png';
import Product3 from '../images/products/product-3.png';
// import Product4 from '../images/products/product-4.png';
import Product5 from '../images/products/product-5.png';
import Product6 from '../images/products/product-6.png';

// Helpers
import { classNames, string_to_slug } from '../helpers/common';

const products = [
  {
    image: Product1,
    name: "INVENSE GATEWAYS",
    applications: [
      "Remote Terminal Units",
      "Network Gateways",
      "Smart Cities",
      "Petrol Pumps",
      "Vending Machines",
      "Data Logger",
      "Sensor Node",
      "Telematics",
      "Real Time Localisation Anchors"
    ],
    description: (
      <>
        <p className="">
          Multiprotocol wired and/or wireless gateways and control units.
        </p>
      </>
    )
  },
  {
    image: Product3,
    name: "INVENSE SENSOR NODES",
    applications: [
      "Precision Asset Tracking",
      "Wireless Beacons",
      "Condition Monitoring",
      "Wireless Sensor Network",
      "Supply Chain Management",
      "Warehouse Management"
    ],
    description: (
      <>
        <p className="">
          Battery operated customizable sensor tags and real time localization modules. Supports BLE, UWB, SubGhz, LoRaWAN and custom RF protocols.<br/>
        </p>
      </>
    )
  },
  {
    image: Product5,
    name: "INVENSE IoT NETWORK STACK",
    applications: [],
    description: (
      <>
        <p className="">
          Confused on which IoT hardware and protocols to be used?
        </p>
        <br />
        <p>
          Using our network stack, acquire and send data to devices,  and run FOTA updates with just few customizable APIs without changing your existing architecture. Reduce your time to market significantly.
        </p>
      </>
    )
  },
  {
    image: Product6,
    name: "INVENSE SOFTWARE STACK",
    applications: [],
    description: (
      <p className="">
        Customizable UI/UX, canned reports, advanced data processing algorithms,  descriptive and diagnostic data analytics , Control Room
      </p>
    )
  }
]

// markup
const Products = props => {
  const currentProductId = props.location.hash || `#${string_to_slug(products[0].name)}`
  const productsWithId = products.map(product => ({ ...product, id: `#${string_to_slug(product.name)}`}));

  const currnetProduct = productsWithId.find(({ id }) => id === currentProductId) || productsWithId[0];
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ currnetProduct.name } | Products | INVENSE - Internet Of Things Telematic Solutions</title>
        <meta name="description" content="Products of Invesen Technologies Inc." />
        <meta name="keyboards" content="IOT, IOT solutions, IOT consultants, engineering" />
      </Helmet>
      <main>
        <Header />
        <section
          id="hero"
          className="py-32"
          style={{ backgroundImage: `url(${citybackground})`, backgroundSize: 'cover' }}
        >
          <div className="max-w-6xl mx-auto pt-20 px-2 sm:px-6 lg:px-8 h-full flex items-center md:justify-center">
            <div className="relative flex-1">
                <div className="border-l-2 border-yellow-500 pl-2">
                    <h3 className="text-2xl text-white">Power Your Business with</h3>
                    <h1 className="block text-4xl text-yellow-500 font-bold">Invense</h1>
                </div>
                <h2 className="text-4xl text-white font-bold px-2 mt-8">Products</h2>
            </div>
          </div>
        </section>
        <section className="max-w-6xl mx-auto py-10 px-2 sm:px-6 lg:px-10">
            <div className="md:flex gap-x-3">
                {
                    productsWithId.map(product => {
                        const isCurentProduct = currentProductId === product.id;
                        const containerClass = classNames(
                          'flex-1 p-3 flex items-center justify-center',
                          isCurentProduct ? 'bg-primary text-white' : 'bg-gray-200 text-primary'
                        );
                        return (
                            <Link
                              to={`/products${product.id}`}
                              className={containerClass}
                            >
                              <h3 className="">
                                {product.name}
                              </h3>
                            </Link>
                        );
                    })
                }
            </div>
            <div className="border-2 border-primary my-10 p-5 lg:px-10" style={{ backgroundImage: `url(${whereToUseInvenseBackground})`, backgroundPositionY: '50%' }}>
                <div className="md:flex mt-5">
                    <div className="flex items-end">
                        <img src={currnetProduct.image} alt={`${currnetProduct.name}`} style={{ width: 400 }} />
                    </div>
                    <div className="flex-1 flex gap-y-5 flex-col">
                        <h1 className="text-4xl text-primary font-bold">{currnetProduct.name}</h1>
                        <div className="flex-1 px-10 py-5 bg-primary text-white">
                          {currnetProduct.description}
                          { currnetProduct.applications.length > 0 && <div className="my-4">Applications: </div> }
                          <div className="flex gap-2 flex-wrap">
                            {
                              currnetProduct.applications.map((application) => {
                                return (
                                  <div className="p-2 border text-white">{application}</div>
                                )
                              })
                            }
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
      </main>
    </>
  )
}

export default Products;
